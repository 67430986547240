@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Source+Sans+Pro:400,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:900&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@font-face {
  font-family: "BentonSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("./BentonSans-Regular.woff"), url("./BentonSans-Regular.woff") format("woff"); }

@font-face {
  font-family: "Alternate-Gothic-No3-D-Regular";
  src: url("./Alternate-Gothic-No3-D-Regular.ttf"); }

@font-face {
  font-family: "ShareTechMono-Regular";
  src: url("./ShareTechMono-Regular.ttf"); }

body {
  background: #0b132e; }

.wrap_forbiz {
  background: #0b132e;
  color: white;
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-style: normal;
  font-weight: 400; }
  .wrap_forbiz p {
    font-family: a-otf-gothic-bbb-pr6n, sans-serif !important;
    font-style: normal;
    font-weight: 400; }
  .wrap_forbiz .thumb-round {
    border-radius: 50%; }
  .wrap_forbiz .btn,
  .wrap_forbiz h1 {
    font-family: "Alternate-Gothic-No3-D-Regular"; }
  .wrap_forbiz h2,
  .wrap_forbiz h3 {
    font-family: "Source Sans Pro", sans-serif; }
  @media screen and (max-width: 767px) {
    .wrap_forbiz .pc_only {
      display: none; } }
  @media screen and (min-width: 768px) {
    .wrap_forbiz .sp_only {
      display: none; } }
  .wrap_forbiz .header {
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0b132e; }
    .wrap_forbiz .header ul {
      position: absolute;
      left: 73.81944vw;
      top: 2.77778vw;
      display: flex;
      padding-left: 0; }
      .wrap_forbiz .header ul li {
        list-style: none; }
        .wrap_forbiz .header ul li a {
          font-size: 0.83333vw;
          padding-right: 0.83333vw;
          text-decoration: none;
          color: white;
          font-family: "ShareTechMono-Regular"; }
        .wrap_forbiz .header ul li a.btn_request {
          text-align: center;
          padding-left: 0.83333vw;
          color: white;
          border-radius: 0.27778vw;
          padding-top: 0.27778vw;
          padding-bottom: 0.27778vw;
          background: #faa51a; }
  .wrap_forbiz .header.bg-dark {
    background-color: #0b132e !important;
    height: 21.33333vw; }
    .wrap_forbiz .header.bg-dark .header_logo {
      height: 10.66667vw;
      width: 40vw; }
    .wrap_forbiz .header.bg-dark .navbar-toggler {
      top: auto; }
    .wrap_forbiz .header.bg-dark .navbar-toggler-icon {
      background: white !important; }
      .wrap_forbiz .header.bg-dark .navbar-toggler-icon:nth-child(2) {
        margin-left: -1.06667vw; }
  .wrap_forbiz .kv_wrap_forbiz {
    position: relative;
    background-size: 100% auto;
    background-position: top center;
    margin-bottom: 6.25vw; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .kv_wrap_forbiz {
        background-size: 100% auto;
        margin-top: 21.33333vw; } }
    .wrap_forbiz .kv_wrap_forbiz::before {
      content: "";
      display: block;
      padding-top: 50%; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz::before {
          padding-top: 133.5%; } }
    .wrap_forbiz .kv_wrap_forbiz .movie-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      overflow: hidden;
      max-height: 770px; }
      @media (max-width: 1280px) {
        .wrap_forbiz .kv_wrap_forbiz .movie-wrapper {
          width: 150vw;
          left: -50vw;
          top: 0; } }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz .movie-wrapper {
          max-height: 133.33333vw;
          margin-top: 0;
          opacity: 0.6;
          width: 100%;
          left: 0;
          top: 0; } }
    .wrap_forbiz .kv_wrap_forbiz .movie {
      margin: 0px auto 0;
      min-height: 700px;
      background-size: cover;
      width: 100%;
      opacity: 0.6; }
      @media (max-width: 1280px) {
        .wrap_forbiz .kv_wrap_forbiz .movie {
          height: 100%;
          width: auto; } }
    @media (max-width: 767px) {
      .wrap_forbiz .kv_wrap_forbiz .movie {
        margin: 0px auto 0;
        transform: translateX(-30%);
        opacity: 0.6;
        width: auto;
        height: 133.33333vw; } }
    @media (max-width: 400px) {
      .wrap_forbiz .kv_wrap_forbiz .movie {
        transform: translateX(-40%); } }
    .wrap_forbiz .kv_wrap_forbiz .title_wrap {
      position: absolute;
      top: 19.79167vw;
      left: 14.51389vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz .title_wrap {
          left: 5.33333vw;
          top: 71.46667vw; } }
    .wrap_forbiz .kv_wrap_forbiz h1 {
      font-size: 4.16667vw;
      letter-spacing: 0.05208vw;
      line-height: 5vw;
      color: white; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz h1 {
          font-size: 9.6vw;
          line-height: 9.6vw;
          letter-spacing: 0.2vw; } }
    .wrap_forbiz .kv_wrap_forbiz .btn_ask {
      display: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz .btn_ask {
          display: block;
          position: absolute;
          top: 59.73333vw;
          width: 89.33333vw;
          z-index: 99; }
          .wrap_forbiz .kv_wrap_forbiz .btn_ask img {
            width: 100%; } }
    .wrap_forbiz .kv_wrap_forbiz .subtitle {
      position: relative;
      color: white;
      font-size: 1.25vw;
      line-height: 1.66667vw;
      letter-spacing: 0.06944vw;
      padding-left: 5.69444vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .kv_wrap_forbiz .subtitle {
          font-size: 4.26667vw;
          line-height: 6.4vw;
          letter-spacing: 0.26667vw;
          padding-left: 16vw;
          width: 82.13333vw; } }
      .wrap_forbiz .kv_wrap_forbiz .subtitle:before {
        left: 0;
        position: absolute;
        background: white;
        content: "";
        display: block;
        margin-top: 0.76389vw;
        width: 5.13889vw;
        height: 1px; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .kv_wrap_forbiz .subtitle:before {
            margin-top: 2.66667vw;
            width: 13.33333vw; } }
  .wrap_forbiz .sidenav {
    z-index: 9;
    top: 0;
    left: 0;
    position: fixed;
    width: 8.75vw;
    height: 53.88889vw; }
    .wrap_forbiz .sidenav .logo {
      width: 3.47222vw;
      margin-top: 2.77778vw;
      margin-left: 2.77778vw; }
    .wrap_forbiz .sidenav .f_tf {
      margin-top: 1.38889vw;
      margin-left: 3.88889vw;
      width: 1.11111vw; }
    .wrap_forbiz .sidenav .f_nav {
      position: absolute;
      bottom: 0;
      left: 4.02778vw;
      width: 0.83333vw; }
  .wrap_forbiz .contentnav {
    position: fixed;
    top: 0;
    right: 0;
    width: 5.55556vw;
    height: 100vh; }
    .wrap_forbiz .contentnav ul {
      margin-top: 8.33333vw;
      list-style: none;
      padding-left: 0.27778vw; }
      .wrap_forbiz .contentnav ul li {
        padding-bottom: 4.30556vw; }
        .wrap_forbiz .contentnav ul li a {
          color: rgba(255, 255, 255, 0.25) !important;
          padding-left: 0.55556vw;
          position: relative;
          line-height: 1.66667vw;
          font-size: 0.83333vw;
          font-family: "ShareTechMono-Regular"; }
          .wrap_forbiz .contentnav ul li a:before {
            left: 0;
            top: 12px;
            position: absolute;
            background: rgba(255, 255, 255, 0.25);
            content: "";
            display: block;
            width: 0.55556vw;
            height: 1px; }
      .wrap_forbiz .contentnav ul li.active a {
        padding-left: 0.55556vw;
        position: relative;
        line-height: 1.66667vw;
        font-size: 0.83333vw;
        color: white !important;
        font-family: "ShareTechMono-Regular"; }
        .wrap_forbiz .contentnav ul li.active a:before {
          left: 0;
          top: 0.83333vw;
          position: absolute;
          background: #faa51a;
          content: "";
          display: block;
          width: 0.55556vw;
          height: 1px; }
  .wrap_forbiz .footer {
    z-index: 9;
    position: fixed;
    bottom: 0;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .footer {
        position: inherit; } }
    .wrap_forbiz .footer_content {
      margin: 0 auto 2.77778vw;
      width: 79.72222vw;
      display: flex;
      justify-content: space-between; }
    .wrap_forbiz .footer span {
      display: block;
      font-size: 8px;
      letter-spacing: 1px;
      line-height: 24px;
      opacity: 0.25;
      font-family: "ShareTechMono-Regular"; }
    .wrap_forbiz .footer span.active {
      opacity: 1; }
  .wrap_forbiz .section_wwd {
    position: relative;
    background: url("/assets/images/forbiz/bg_wwd.png") no-repeat;
    background-size: 100% auto;
    background-position: top center; }
    .wrap_forbiz .section_wwd::before {
      content: "";
      display: block;
      padding-top: 75%; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_wwd::before {
          padding-top: 220%; } }
    .wrap_forbiz .section_wwd .particle {
      position: absolute;
      left: 0;
      top: -11.11111vw;
      width: 100%; }
    .wrap_forbiz .section_wwd .wrap_contents {
      position: absolute;
      top: 9.02778vw;
      left: 10.13889vw;
      display: flex;
      width: 81.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_wwd .wrap_contents {
          width: 89.33333vw;
          left: 5.33333vw;
          flex-wrap: wrap; } }
      .wrap_forbiz .section_wwd .wrap_contents .block_contents {
        width: 50%; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_wwd .wrap_contents .block_contents:first-child {
            order: 2;
            width: 89.33333vw; } }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_wwd .wrap_contents .block_contents:last-child {
            order: 1;
            width: 89.33333vw; } }
        .wrap_forbiz .section_wwd .wrap_contents .block_contents h1 {
          font-size: 4.16667vw;
          line-height: 5vw;
          letter-spacing: 0.05208vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_wwd .wrap_contents .block_contents h1 {
              order: 1;
              width: 89.33333vw;
              font-size: 9.6vw;
              line-height: 9.6vw;
              letter-spacing: 0.2vw; } }
        .wrap_forbiz .section_wwd .wrap_contents .block_contents .subtitle {
          margin-top: 2.22222vw;
          margin-bottom: 3.61111vw;
          position: relative;
          color: white;
          font-size: 1.11111vw;
          line-height: 1.66667vw;
          letter-spacing: 0.06944vw;
          padding-left: 5.69444vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_wwd .wrap_contents .block_contents .subtitle {
              font-size: 4.26667vw;
              line-height: 6.4vw;
              letter-spacing: 0.26667vw;
              padding-left: 16vw;
              width: 82.13333vw; } }
          .wrap_forbiz .section_wwd .wrap_contents .block_contents .subtitle:before {
            left: 0;
            position: absolute;
            background: white;
            content: "";
            display: block;
            margin-top: 0.76389vw;
            width: 5.13889vw;
            height: 1px; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_wwd .wrap_contents .block_contents .subtitle:before {
                margin-top: 2.66667vw;
                width: 13.33333vw; } }
        .wrap_forbiz .section_wwd .wrap_contents .block_contents p.desc {
          font-size: 0.97222vw;
          line-height: 1.66667vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_wwd .wrap_contents .block_contents p.desc {
              margin-top: 2.66667vw;
              width: 89.33333vw;
              font-size: 3.73333vw;
              line-height: 6.4vw; } }
      .wrap_forbiz .section_wwd .wrap_contents .title_exp {
        display: block;
        margin-top: 16.31944vw;
        margin-bottom: 1.38889vw;
        font-size: 1.66667vw;
        line-height: 1.66667vw;
        letter-spacing: 0.05208vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_wwd .wrap_contents .title_exp {
            margin-top: 2.66667vw;
            width: 89.33333vw;
            font-size: 6.4vw;
            line-height: 6.4vw;
            letter-spacing: 0.2vw;
            margin-bottom: 5.33333vw; } }
      .wrap_forbiz .section_wwd .wrap_contents .block_exp {
        width: 32.5vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_wwd .wrap_contents .block_exp {
            margin-top: 2.66667vw;
            width: 89.33333vw; } }
        .wrap_forbiz .section_wwd .wrap_contents .block_exp img {
          width: 8.75vw;
          margin-right: 1.38889vw;
          margin-bottom: 1.38889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_wwd .wrap_contents .block_exp img {
              width: 26.4vw;
              margin-right: 4.8vw;
              margin-bottom: 4.8vw; }
              .wrap_forbiz .section_wwd .wrap_contents .block_exp img:nth-child(3n) {
                margin-right: 0; } }
  .wrap_forbiz .section_stg {
    position: relative; }
    .wrap_forbiz .section_stg::before {
      content: "";
      display: block;
      padding-top: 60%; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg::before {
          padding-top: 330%; } }
    .wrap_forbiz .section_stg .bg_image {
      position: absolute;
      top: 6.94444vw;
      left: 33.33333vw;
      width: 33.14028vw;
      height: 33.57917vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .bg_image {
          top: 0;
          left: 0;
          width: 100vw;
          height: 287.73333vw; } }
    .wrap_forbiz .section_stg .wrap_contents {
      position: absolute;
      display: flex;
      flex-flow: row wrap;
      width: 79.72222vw;
      top: 0;
      left: 10.13889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .wrap_contents {
          width: 89.33333vw;
          top: 0;
          left: 5.33333vw; } }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_stg .content_block {
        width: 89.33333vw; } }
    .wrap_forbiz .section_stg .content_block label {
      background: #faa51a;
      font-size: 0.83333vw;
      letter-spacing: 0.05208vw;
      line-height: 1.38889vw;
      padding-left: 0.55556vw;
      padding-right: 0.55556vw;
      margin-bottom: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block label {
          font-size: 3.2vw;
          letter-spacing: 0.2vw;
          line-height: 5.33333vw;
          padding-left: 2.13333vw;
          padding-right: 2.13333vw;
          margin-bottom: 6.4vw; } }
    .wrap_forbiz .section_stg .content_block h1 {
      font-size: 3.33333vw;
      line-height: 4.16667vw;
      letter-spacing: 0.05208vw;
      margin-bottom: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block h1 {
          font-size: 9.6vw;
          line-height: 9.6vw;
          letter-spacing: 0.2vw;
          margin-bottom: 6.4vw; } }
    .wrap_forbiz .section_stg .content_block .subtitle {
      margin-top: 0;
      margin-bottom: 2.77778vw;
      position: relative;
      color: white;
      font-size: 1.11111vw;
      line-height: 1.66667vw;
      letter-spacing: 0.06944vw;
      padding-left: 5.69444vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block .subtitle {
          font-size: 4.26667vw;
          line-height: 6.4vw;
          letter-spacing: 0.26667vw;
          padding-left: 16vw;
          width: 82.13333vw; } }
      .wrap_forbiz .section_stg .content_block .subtitle:before {
        left: 0;
        position: absolute;
        background: white;
        content: "";
        display: block;
        margin-top: 0.76389vw;
        width: 5.13889vw;
        height: 1px; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_stg .content_block .subtitle:before {
            margin-top: 2.66667vw;
            width: 13.33333vw; } }
    .wrap_forbiz .section_stg .content_block .desc {
      font-size: 0.97222vw;
      line-height: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block .desc {
          margin-top: 2.66667vw;
          width: 89.33333vw;
          font-size: 3.73333vw;
          line-height: 6.4vw; } }
    .wrap_forbiz .section_stg .content_block:first-child {
      margin-top: 4.16667vw;
      width: 50.55556vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block:first-child {
          margin-top: 16vw;
          width: 89.33333vw; } }
      .wrap_forbiz .section_stg .content_block:first-child .desc {
        width: 39.16667vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_stg .content_block:first-child .desc {
            width: 89.33333vw; } }
    .wrap_forbiz .section_stg .content_block:nth-child(2) {
      width: 29.02778vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block:nth-child(2) {
          width: 89.33333vw; } }
    .wrap_forbiz .section_stg .content_block:nth-child(3) {
      margin-top: 2.77778vw;
      margin-left: 14.16667vw;
      width: 65.55556vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block:nth-child(3) {
          width: 89.33333vw;
          margin-top: 10.66667vw;
          margin-left: 0; } }
      .wrap_forbiz .section_stg .content_block:nth-child(3) .desc {
        width: 39.16667vw;
        display: inline-block;
        margin-right: 7.5vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_stg .content_block:nth-child(3) .desc {
            width: 89.33333vw;
            margin-right: 0; } }
    .wrap_forbiz .section_stg .content_block .more {
      background: rgba(250, 165, 25, 0.25);
      padding-left: 4.86111vw;
      padding-top: 0.55556vw;
      padding-bottom: 0.55556vw;
      padding-right: 0.83333vw;
      color: white;
      text-decoration: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_stg .content_block .more {
          padding-left: 38.66667vw;
          padding-top: 2.13333vw;
          padding-bottom: 2.13333vw;
          padding-right: 3.2vw; } }
      .wrap_forbiz .section_stg .content_block .more img {
        width: 1.52778vw;
        height: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_stg .content_block .more img {
            width: 5.86667vw;
            height: 3.2vw; } }
      .wrap_forbiz .section_stg .content_block .more span {
        font-size: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_stg .content_block .more span {
            font-size: 3.2vw; } }
  .wrap_forbiz .section_prg {
    position: relative; }
    .wrap_forbiz .section_prg::before {
      content: "";
      display: block;
      padding-top: 60%; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg::before {
          padding-top: 305%; } }
    .wrap_forbiz .section_prg .bg_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 63.61111vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .bg_image {
          width: 100vw;
          height: 244.53333vw; } }
    .wrap_forbiz .section_prg .wrap_contents {
      position: absolute;
      display: flex;
      flex-flow: row wrap;
      width: 79.72222vw;
      top: 6.94444vw;
      left: 10.13889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .wrap_contents {
          width: 89.33333vw;
          top: 0;
          left: 0; } }
    .wrap_forbiz .section_prg .content_block h1 {
      font-size: 3.33333vw;
      line-height: 4.16667vw;
      letter-spacing: 0.05208vw;
      margin-bottom: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .content_block h1 {
          font-size: 9.6vw;
          line-height: 9.6vw;
          letter-spacing: 0.2vw;
          margin-bottom: 6.4vw; } }
    .wrap_forbiz .section_prg .content_block .subtitle {
      margin-top: 0;
      margin-bottom: 2.77778vw;
      position: relative;
      color: white;
      font-size: 1.11111vw;
      line-height: 2.22222vw;
      letter-spacing: 0.06944vw;
      padding-left: 5.69444vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .content_block .subtitle {
          font-size: 4.26667vw;
          line-height: 6.4vw;
          letter-spacing: 0.26667vw;
          padding-left: 16vw;
          width: 88vw; } }
      .wrap_forbiz .section_prg .content_block .subtitle:before {
        left: 0;
        position: absolute;
        background: white;
        content: "";
        display: block;
        margin-top: 0.76389vw;
        width: 5.13889vw;
        height: 1px; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_prg .content_block .subtitle:before {
            margin-top: 2.66667vw;
            width: 13.33333vw; } }
    .wrap_forbiz .section_prg .content_block .flow_image {
      width: 79.72222vw;
      height: 13.33333vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .content_block .flow_image {
          width: 89.33333vw;
          height: auto; } }
    .wrap_forbiz .section_prg .content_block:first-child {
      padding-left: 30.41667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .content_block:first-child {
          display: block;
          padding-top: 8vw;
          padding-left: 5.33333vw; } }
    .wrap_forbiz .section_prg .content_block:nth-child(3) {
      display: flex;
      padding-top: 2.08333vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_prg .content_block:nth-child(3) {
          display: block;
          padding-top: 8vw; } }
      .wrap_forbiz .section_prg .content_block:nth-child(3) div:first-child {
        width: 23.95833vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_prg .content_block:nth-child(3) div:first-child {
            width: 89.33333vw;
            margin-left: 5.33333vw; } }
      .wrap_forbiz .section_prg .content_block:nth-child(3) div:nth-child(2) {
        width: 29.02778vw;
        margin-left: 1.38889vw;
        margin-right: 1.38889vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_prg .content_block:nth-child(3) div:nth-child(2) {
            width: 89.33333vw;
            margin-left: 5.33333vw;
            margin-right: 0; } }
      .wrap_forbiz .section_prg .content_block:nth-child(3) div:nth-child(3) {
        width: 23.95833vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_prg .content_block:nth-child(3) div:nth-child(3) {
            width: 89.33333vw;
            margin-left: 5.33333vw; } }
      .wrap_forbiz .section_prg .content_block:nth-child(3) p {
        font-size: 0.97222vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_prg .content_block:nth-child(3) p {
            margin-top: 10.66667vw;
            margin-bottom: 21.33333vw;
            font-size: 3.73333vw; } }
  .wrap_forbiz .section_topics {
    position: relative; }
    .wrap_forbiz .section_topics .content_footer {
      width: 100%;
      margin: -0.83333vw auto 0;
      margin-bottom: 200px;
      width: 79.72222vw;
      text-align: right; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_topics .content_footer {
          width: 89.33333vw;
          margin-left: 5.33333vw; } }
    .wrap_forbiz .section_topics .more_all {
      background: rgba(250, 165, 25, 0.25);
      padding-left: 4.86111vw;
      padding-top: 0.55556vw;
      padding-bottom: 0.55556vw;
      padding-right: 0.83333vw;
      color: white;
      text-decoration: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_topics .more_all {
          padding-left: 54.4vw;
          padding-top: 2.13333vw;
          padding-bottom: 2.13333vw;
          padding-right: 3.2vw; } }
      .wrap_forbiz .section_topics .more_all img {
        width: 1.52778vw;
        height: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .more_all img {
            width: 5.86667vw;
            height: 3.2vw; } }
      .wrap_forbiz .section_topics .more_all span {
        font-size: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .more_all span {
            font-size: 3.2vw; } }
    .wrap_forbiz .section_topics .wrap_contents {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 6.80556vw auto 0;
      width: 79.72222vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_topics .wrap_contents {
          flex-wrap: wrap;
          width: 100vw;
          margin: 26.13333vw auto 0; } }
      .wrap_forbiz .section_topics .wrap_contents .grid a {
        color: white;
        text-decoration: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_topics .wrap_contents .grid:first-child {
          margin-bottom: 8.53333vw; } }
      .wrap_forbiz .section_topics .wrap_contents .content_block.lg {
        width: 29.02778vw;
        margin-right: 11.25vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .wrap_contents .content_block.lg {
            width: 89.33333vw;
            margin-left: 5.33333vw;
            margin-right: 0; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg .thumb, .wrap_forbiz .section_topics .wrap_contents .content_block.lg .thumb_active {
          width: 29.02778vw;
          margin-bottom: 1.38889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.lg .thumb, .wrap_forbiz .section_topics .wrap_contents .content_block.lg .thumb_active {
              width: 42.66667vw;
              margin-bottom: 5.33333vw;
              vertical-align: top;
              margin-right: 2.66667vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg .thumb_active {
          display: none; }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg:hover .thumb {
          display: none; }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg:hover .thumb_active {
          display: block; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .wrap_contents .content_block.lg .text_block {
            width: 42.66667vw;
            display: inline-block; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg h3 {
          font-size: 1.66667vw;
          line-height: 2.5vw;
          margin-bottom: 1.38889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.lg h3 {
              font-size: 4.26667vw;
              line-height: 5.33333vw;
              margin-bottom: 2.66667vw;
              width: 34.66667vw; } }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .wrap_contents .content_block.lg .tag {
            width: 42.66667vw;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg .tag span {
          font-size: 0.83333vw;
          line-height: 1.38889vw;
          padding-top: 0.13889vw;
          padding-bottom: 0.13889vw;
          padding-left: 0.55556vw;
          padding-right: 0.55556vw;
          margin-right: 0.69444vw;
          margin-bottom: 0.69444vw;
          background: #faa51a; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.lg .tag span {
              font-size: 2.66667vw;
              line-height: 3.2vw;
              padding-top: 0.53333vw;
              padding-bottom: 0.53333vw;
              padding-left: 1.06667vw;
              padding-right: 1.06667vw;
              margin-right: 1.6vw;
              margin-bottom: 2.13333vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg .desc {
          margin-top: 1.38889vw;
          margin-bottom: 2.77778vw;
          font-size: 0.97222vw;
          line-height: 1.66667vw;
          color: rgba(255, 255, 255, 0.5);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.lg .desc {
              margin-top: 0;
              margin-bottom: 0;
              font-size: 3.2vw;
              line-height: 4.8vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more {
          padding-top: 0.55556vw;
          padding-bottom: 0.55556vw;
          padding-right: 0.83333vw;
          color: white;
          text-decoration: none; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more {
              margin-left: 17.06667vw;
              padding-top: 2.13333vw;
              padding-bottom: 2.13333vw;
              padding-right: 3.2vw; } }
          .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more img {
            width: 1.52778vw;
            height: 0.83333vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more img {
                width: 5.86667vw;
                height: 3.2vw; } }
          .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more span {
            font-size: 0.83333vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_topics .wrap_contents .content_block.lg .more span {
                font-size: 3.2vw; } }
      .wrap_forbiz .section_topics .wrap_contents .content_block.sm {
        width: 39.16667vw;
        margin-bottom: 2.77778vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_topics .wrap_contents .content_block.sm {
            width: 89.33333vw;
            margin-left: 5.33333vw;
            margin-right: 0;
            margin-bottom: 8.53333vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .thumb, .wrap_forbiz .section_topics .wrap_contents .content_block.sm .thumb_active {
          margin-top: 0.55556vw;
          display: inline-block;
          width: 18.88889vw;
          margin-right: 1.38889vw;
          vertical-align: top; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .thumb, .wrap_forbiz .section_topics .wrap_contents .content_block.sm .thumb_active {
              width: 42.66667vw;
              margin-bottom: 5.33333vw;
              margin-right: 2.66667vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .thumb_active {
          display: none; }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm:hover .thumb {
          display: none; }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm:hover .thumb_active {
          display: inline-block; }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .text_block {
          display: inline-block;
          width: 18.88889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .text_block {
              width: 42.66667vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm h3 {
          font-size: 1.66667vw;
          line-height: 2.5vw;
          margin-bottom: 1.38889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm h3 {
              font-size: 4.26667vw;
              line-height: 5.33333vw;
              margin-bottom: 2.66667vw;
              width: 34.66667vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .tag {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .tag {
              width: 42.66667vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .tag span {
          font-size: 0.83333vw;
          line-height: 1.38889vw;
          padding-top: 0.13889vw;
          padding-bottom: 0.13889vw;
          padding-left: 0.55556vw;
          padding-right: 0.55556vw;
          margin-right: 0.69444vw;
          margin-bottom: 0.69444vw;
          background: #faa51a; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .tag span {
              font-size: 2.66667vw;
              line-height: 3.2vw;
              padding-top: 0.53333vw;
              padding-bottom: 0.53333vw;
              padding-left: 1.06667vw;
              padding-right: 1.06667vw;
              margin-right: 1.6vw;
              margin-bottom: 2.13333vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .desc {
          margin-top: 1.38889vw;
          font-size: 0.97222vw;
          line-height: 1.66667vw;
          color: rgba(255, 255, 255, 0.5);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .desc {
              margin-top: 0;
              margin-bottom: 0;
              font-size: 3.2vw;
              line-height: 4.8vw; } }
        .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more {
          padding-top: 0.55556vw;
          padding-bottom: 0.55556vw;
          padding-right: 0.83333vw;
          color: white;
          text-decoration: none; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more {
              margin-left: 17.06667vw;
              padding-top: 2.13333vw;
              padding-bottom: 2.13333vw;
              padding-right: 3.2vw; } }
          .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more img {
            width: 1.52778vw;
            height: 0.83333vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more img {
                width: 5.86667vw;
                height: 3.2vw; } }
          .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more span {
            font-size: 0.83333vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_topics .wrap_contents .content_block.sm .more span {
                font-size: 3.2vw; } }
  .wrap_forbiz .page_topics {
    position: relative;
    background: url("/assets/images/forbiz/particle.svg") no-repeat;
    background-size: 100% auto;
    background-position: top center; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .page_topics {
        background: none;
        margin-top: 21.33333vw; } }
    .wrap_forbiz .page_topics::before {
      content: "";
      display: block;
      padding-top: 75%; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .page_topics::before {
          padding-top: 0%; } }
    .wrap_forbiz .page_topics .wrap_topics {
      position: absolute;
      top: 7.77778vw;
      left: 10.13889vw;
      width: 79.72222vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .page_topics .wrap_topics {
          position: relative;
          width: 89.33333vw;
          left: auto;
          top: auto;
          margin: 0 auto; } }
    .wrap_forbiz .page_topics .topic_header {
      position: relative; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .page_topics .topic_header {
          margin-bottom: 10.66667vw; } }
      .wrap_forbiz .page_topics .topic_header h1 {
        font-size: 4.16667vw;
        letter-spacing: 0.05208vw;
        line-height: 5vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .page_topics .topic_header h1 {
            font-size: 12.8vw;
            letter-spacing: 0.2vw;
            line-height: 16vw; } }
      .wrap_forbiz .page_topics .topic_header .list_topics {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        line-height: 5vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .page_topics .topic_header .list_topics {
            position: relative; } }
        .wrap_forbiz .page_topics .topic_header .list_topics span {
          padding-top: 0.55556vw;
          padding-bottom: 0.55556vw;
          margin-right: 20px;
          cursor: pointer; }
          .wrap_forbiz .page_topics .topic_header .list_topics span:last-child {
            margin-right: 0; }
        .wrap_forbiz .page_topics .topic_header .list_topics .active {
          color: #faa51a; }
    .wrap_forbiz .page_topics .topic_body {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .wrap_forbiz .page_topics .topic_body a {
        color: white;
        text-decoration: none; }
      .wrap_forbiz .page_topics .topic_body .topic_block {
        width: 18.88889vw;
        margin-right: 1.38889vw;
        margin-bottom: 5.55556vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .page_topics .topic_body .topic_block {
            width: 89.33333vw;
            margin-right: 0; } }
        .wrap_forbiz .page_topics .topic_body .topic_block:nth-child(4n) {
          margin-right: 0; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .page_topics .topic_body .topic_block .text_block {
            width: 42.66667vw;
            display: inline-block; } }
        .wrap_forbiz .page_topics .topic_body .topic_block .text_block .desc {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .wrap_forbiz .page_topics .topic_body .topic_block .thumb, .wrap_forbiz .page_topics .topic_body .topic_block .thumb_active {
          width: 18.88889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .page_topics .topic_body .topic_block .thumb, .wrap_forbiz .page_topics .topic_body .topic_block .thumb_active {
              width: 42.66667vw;
              margin-bottom: 5.33333vw;
              margin-right: 2.66667vw;
              vertical-align: top; } }
        .wrap_forbiz .page_topics .topic_body .topic_block .thumb_active {
          display: none; }
        .wrap_forbiz .page_topics .topic_body .topic_block:hover .thumb {
          display: none; }
        .wrap_forbiz .page_topics .topic_body .topic_block:hover .thumb_active {
          display: block; }
        .wrap_forbiz .page_topics .topic_body .topic_block h4 {
          font-size: 1.66667vw;
          margin-top: 1.38889vw;
          margin-bottom: 1.38889vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .page_topics .topic_body .topic_block h4 {
              font-size: 4.26667vw;
              margin-top: 0;
              margin-bottom: 2.66667vw; } }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .page_topics .topic_body .topic_block .tag {
            width: 42.66667vw;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; } }
        .wrap_forbiz .page_topics .topic_body .topic_block .tag span {
          font-size: 0.83333vw;
          line-height: 1.38889vw;
          padding-top: 0.13889vw;
          padding-bottom: 0.13889vw;
          padding-left: 0.55556vw;
          padding-right: 0.55556vw;
          margin-right: 0.69444vw;
          margin-bottom: 0.69444vw;
          background: #faa51a; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .page_topics .topic_body .topic_block .tag span {
              font-size: 2.66667vw;
              line-height: 3.2vw;
              padding-top: 0.53333vw;
              padding-bottom: 0.53333vw;
              padding-left: 1.06667vw;
              padding-right: 1.06667vw;
              margin-right: 1.6vw;
              margin-bottom: 2.13333vw; } }
        .wrap_forbiz .page_topics .topic_body .topic_block p {
          margin-top: 1.38889vw;
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.97222vw;
          line-height: 1.66667vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .page_topics .topic_body .topic_block p {
              margin-top: 0;
              font-size: 3.2vw;
              line-height: 4.8vw;
              margin-bottom: 0; } }
        .wrap_forbiz .page_topics .topic_body .topic_block a {
          color: white;
          text-decoration: none; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .page_topics .topic_body .topic_block a {
              font-size: 2.93333vw;
              float: right; } }
          .wrap_forbiz .page_topics .topic_body .topic_block a .icon_arrow {
            height: 0.69444vw;
            width: 0.69444vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .page_topics .topic_body .topic_block a .icon_arrow {
                height: 2.66667vw;
                width: 2.66667vw; } }
  .wrap_forbiz .page_topics_detail {
    width: 79.72222vw;
    max-width: 1148px;
    margin: 8.19444vw auto; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .page_topics_detail {
        width: 89.33333vw;
        margin: 8.19444vw auto; } }
    .wrap_forbiz .page_topics_detail .thumb {
      width: 100%;
      margin-bottom: 40px;
      margin-top: 0vw; }
    .wrap_forbiz .page_topics_detail .title {
      font-size: 36px;
      line-height: 48px; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .page_topics_detail .title {
          font-size: 7.46667vw;
          line-height: 9.6vw; } }
    .wrap_forbiz .page_topics_detail .tag {
      display: flex; }
      .wrap_forbiz .page_topics_detail .tag span {
        font-size: 12px;
        line-height: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #faa51a; }
    .wrap_forbiz .page_topics_detail .content_body p {
      padding: 16px;
      line-height: 36px;
      margin-bottom: 56px;
      white-space: pre-wrap; }
    .wrap_forbiz .page_topics_detail .content_body .quote {
      padding: 16px;
      line-height: 36px;
      background: rgba(255, 255, 255, 0.25);
      border-radius: 4px;
      margin-bottom: 56px; }
    .wrap_forbiz .page_topics_detail .content_body h3 {
      color: #faa51a;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 56px; }
    .wrap_forbiz .page_topics_detail .content_body h4 {
      color: #faa51a;
      font-size: 16px;
      line-height: 36px;
      margin-bottom: 56px; }
  .wrap_forbiz .f_yellow {
    color: #faa51a; }
  .wrap_forbiz .section_member {
    margin: 13.61111vw auto 5.55556vw;
    width: 79.72222vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_member {
        flex-wrap: wrap;
        margin: 52.26667vw auto 21.33333vw;
        width: 89.33333vw; } }
    .wrap_forbiz .section_member .member_block {
      width: 18.88889vw;
      margin-right: 1.38889vw; }
      .wrap_forbiz .section_member .member_block:nth-child(4n) {
        margin-right: 0; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_member .member_block {
          width: 42.66667vw;
          margin-right: 3.73333vw; }
          .wrap_forbiz .section_member .member_block:nth-child(2n) {
            margin-right: 0; } }
    .wrap_forbiz .section_member .thumb_cover {
      width: 18.88889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_member .thumb_cover {
          width: 42.66667vw; } }
      .wrap_forbiz .section_member .thumb_cover .thumb {
        width: 18.88889vw;
        display: block; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_member .thumb_cover .thumb {
            width: 42.66667vw; } }
      .wrap_forbiz .section_member .thumb_cover .thumb_active {
        width: 18.88889vw;
        display: none; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_member .thumb_cover .thumb_active {
            width: 42.66667vw; } }
      .wrap_forbiz .section_member .thumb_cover:hover .thumb {
        display: none; }
      .wrap_forbiz .section_member .thumb_cover:hover .thumb_active {
        display: block; }
    .wrap_forbiz .section_member .role {
      margin-top: 1.38889vw;
      margin-bottom: 1.38889vw;
      display: block;
      font-size: 0.83333vw;
      line-height: 1.04167vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_member .role {
          margin-top: 5.33333vw;
          margin-bottom: 2.66667vw;
          display: block;
          font-size: 3.2vw;
          line-height: 4vw; } }
    .wrap_forbiz .section_member h3 {
      font-size: 1.66667vw;
      line-height: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_member h3 {
          font-size: 4.26667vw;
          line-height: 6.4vw; } }
    .wrap_forbiz .section_member .desc {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.97222vw;
      line-height: 1.66667vw;
      height: 26.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_member .desc {
          font-size: 3.73333vw;
          line-height: 6.4vw;
          margin-bottom: 16vw;
          height: auto; } }
    .wrap_forbiz .section_member .member_nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; }
    .wrap_forbiz .section_member .link_block {
      text-decoration: none;
      color: #faa51a;
      margin-right: 1.38889vw;
      opacity: 0.6; }
      .wrap_forbiz .section_member .link_block a,
      .wrap_forbiz .section_member .link_block div,
      .wrap_forbiz .section_member .link_block img,
      .wrap_forbiz .section_member .link_block p {
        opacity: 0.6; }
      .wrap_forbiz .section_member .link_block:nth-child(4n) {
        margin-right: 0; }
      .wrap_forbiz .section_member .link_block:hover {
        opacity: 1; }
        .wrap_forbiz .section_member .link_block:hover a,
        .wrap_forbiz .section_member .link_block:hover div,
        .wrap_forbiz .section_member .link_block:hover img,
        .wrap_forbiz .section_member .link_block:hover p {
          opacity: 1; }
    .wrap_forbiz .section_member .link_block.active {
      opacity: 1; }
      .wrap_forbiz .section_member .link_block.active a,
      .wrap_forbiz .section_member .link_block.active div,
      .wrap_forbiz .section_member .link_block.active img,
      .wrap_forbiz .section_member .link_block.active p {
        opacity: 1; }
      .wrap_forbiz .section_member .link_block.active:hover {
        opacity: 1; }
        .wrap_forbiz .section_member .link_block.active:hover a,
        .wrap_forbiz .section_member .link_block.active:hover div,
        .wrap_forbiz .section_member .link_block.active:hover img,
        .wrap_forbiz .section_member .link_block.active:hover p {
          opacity: 1; }
    .wrap_forbiz .section_member .thumb_list_sp {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: 1.38889vw;
      border-bottom: 1px solid #faa51a;
      display: none; }
      .wrap_forbiz .section_member .thumb_list_sp img {
        width: 14.13333vw;
        margin-right: 5.33333vw; }
        .wrap_forbiz .section_member .thumb_list_sp img:last-child {
          margin-right: 0; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_member .thumb_list_sp.active {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: none; } }
    .wrap_forbiz .section_member .member_nav_sp {
      width: 100%;
      position: relative; }
      .wrap_forbiz .section_member .member_nav_sp .a_left {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 4.26667vw;
        padding-bottom: 4.26667vw;
        padding-left: 2.13333vw;
        padding-right: 2.13333vw; }
      .wrap_forbiz .section_member .member_nav_sp .a_right {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 4.26667vw;
        padding-bottom: 4.26667vw;
        padding-left: 2.13333vw;
        padding-right: 2.13333vw; }
    .wrap_forbiz .section_member .thumb_list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: 1.38889vw;
      border-bottom: 1px solid #faa51a; }
      .wrap_forbiz .section_member .thumb_list img {
        width: 3.68056vw;
        margin-right: 1.38889vw; }
        .wrap_forbiz .section_member .thumb_list img:last-child {
          margin-right: 0; }
    .wrap_forbiz .section_member .page_num {
      margin-top: 1.38889vw;
      text-align: center;
      text-decoration: none; }
      .wrap_forbiz .section_member .page_num:hover {
        text-decoration: none; }
  .wrap_forbiz .section_portfolio {
    margin: 13.61111vw auto 5.55556vw;
    width: 79.72222vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_portfolio {
        width: 89.33333vw;
        margin: 0 auto 21.33333vw; } }
    .wrap_forbiz .section_portfolio img {
      display: block;
      width: 18.88889vw;
      margin-right: 1.38889vw; }
      .wrap_forbiz .section_portfolio img:nth-child(4n) {
        margin-right: 0; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_portfolio img {
          display: block;
          margin-left: 0;
          width: 26.93333vw;
          height: 26.93333vw;
          margin-right: 4.26667vw; }
          .wrap_forbiz .section_portfolio img:nth-child(4n) {
            margin-right: 4.26667vw; }
          .wrap_forbiz .section_portfolio img:nth-child(3n) {
            margin-right: 0; } }
  .wrap_forbiz .section_flow_wrap {
    position: relative;
    background: url("/assets/images/forbiz/bg_flow.png") no-repeat;
    background-size: 100% auto;
    background-position: top center;
    margin-bottom: 6.25vw;
    height: 48.33333vw; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_flow_wrap {
        width: 100%;
        background: url("/assets/images/forbiz/bg_flow_sp.svg") no-repeat;
        height: 146.13333vw;
        background-position: bottom; } }
    .wrap_forbiz .section_flow_wrap .section_flow {
      margin: 13.61111vw auto 5.55556vw;
      width: 79.72222vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_flow_wrap .section_flow {
          margin: o auto 21.33333vw;
          width: 89.33333vw; } }
    .wrap_forbiz .section_flow_wrap .flow_block {
      width: 18.88889vw;
      margin-right: 1.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_flow_wrap .flow_block {
          width: 72.53333vw;
          margin-right: 5.33333vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block:nth-child(2) {
        margin-top: 8.88889vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block:nth-child(2) {
            margin-top: 9.06667vw;
            margin-left: 16vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block:nth-child(3) {
        margin-top: 17.77778vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block:nth-child(3) {
            margin-top: 9.06667vw;
            margin-left: 32.53333vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block:nth-child(4) {
        margin-top: 26.66667vw;
        margin-right: 0; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block:nth-child(4) {
            margin-top: 9.06667vw;
            margin-left: 51.73333vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block span {
        font-size: 0.83333vw;
        line-height: 1.38889vw;
        padding-top: 0.13889vw;
        padding-bottom: 0.13889vw;
        padding-left: 0.55556vw;
        padding-right: 0.55556vw;
        margin-right: 0.69444vw;
        margin-bottom: 0.69444vw;
        background: #faa51a; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block span {
            font-size: 3.2vw;
            line-height: 5.33333vw;
            padding-top: 0.53333vw;
            padding-bottom: 0.53333vw;
            padding-left: 2.13333vw;
            padding-right: 2.13333vw;
            margin-right: 2.66667vw;
            margin-bottom: 2.66667vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block h3 {
        font-size: 3.33333vw;
        letter-spacing: 0.05208vw;
        line-height: 4.16667vw;
        margin-top: 1.66667vw;
        margin-bottom: 1.66667vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block h3 {
            font-size: 9.06667vw;
            letter-spacing: 0.2vw;
            line-height: 9.06667vw;
            margin-top: 4.26667vw;
            margin-bottom: 4.26667vw; } }
      .wrap_forbiz .section_flow_wrap .flow_block .subtitle {
        margin-top: 0;
        margin-bottom: 2.63889vw;
        position: relative;
        color: white;
        font-size: 0.97222vw;
        line-height: 1.66667vw;
        letter-spacing: 0.06944vw;
        padding-left: 5.69444vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_flow_wrap .flow_block .subtitle {
            margin-bottom: 0;
            font-size: 3.2vw;
            line-height: 5.86667vw;
            letter-spacing: 0.26667vw;
            padding-left: 16vw; } }
        .wrap_forbiz .section_flow_wrap .flow_block .subtitle:before {
          left: 0;
          position: absolute;
          background: white;
          content: "";
          display: block;
          margin-top: 0.76389vw;
          width: 5.13889vw;
          height: 1px; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_flow_wrap .flow_block .subtitle:before {
              margin-top: 2.93333vw;
              width: 13.33333vw;
              height: 1px; } }
  .wrap_forbiz .section_about {
    margin: 13.61111vw auto 5.55556vw;
    width: 79.72222vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_about {
        width: 89.33333vw;
        margin: 0 auto 21.33333vw;
        flex-wrap: wrap; } }
    .wrap_forbiz .section_about .about_block {
      width: 39.16667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_about .about_block {
          width: 89.33333vw; } }
      .wrap_forbiz .section_about .about_block:first-child {
        margin-right: 1.38889vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_about .about_block:first-child {
            margin-bottom: 13.33333vw; } }
      .wrap_forbiz .section_about .about_block:last-child img {
        width: 100%; }
    .wrap_forbiz .section_about h3 {
      font-size: 3.33333vw;
      letter-spacing: 0.05208vw;
      line-height: 4.16667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_about h3 {
          font-size: 12.8vw;
          letter-spacing: 0.2vw;
          line-height: 16vw; } }
    .wrap_forbiz .section_about table th {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.97222vw;
      letter-spacing: 0.01736vw;
      line-height: 1.66667vw;
      padding-right: 1.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_about table th {
          font-size: 3.73333vw;
          letter-spacing: 0.06667vw;
          line-height: 6.4vw;
          padding-right: 9.6vw; } }
    .wrap_forbiz .section_about table td {
      color: white;
      font-size: 0.97222vw;
      letter-spacing: 0.01736vw;
      line-height: 1.66667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_about table td {
          font-size: 3.73333vw;
          letter-spacing: 0.06667vw;
          line-height: 6.4vw;
          vertical-align: top;
          padding-top: 1.06667vw; } }

@font-face {
  .wrap_forbiz {
    font-family: "IonIcons";
    src: url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1");
    src: url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix") format("embedded-opentype"), url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1") format("truetype"), url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1") format("woff"), url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons") format("svg");
    font-weight: normal;
    font-style: normal; } }
  .wrap_forbiz .page_request .section_contact {
    margin-top: 6.94444vw; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .page_request .section_contact {
        margin-top: 26.66667vw; } }
  .wrap_forbiz .section_contact {
    margin: 13.61111vw auto 0;
    padding-bottom: 13.88889vw;
    width: 79.72222vw;
    display: block;
    font-size: 1.11111vw;
    color: white; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_contact {
        margin: 52.26667vw auto 0;
        padding-bottom: 53.33333vw;
        width: 89.33333vw;
        display: block;
        font-size: 4.26667vw;
        color: white; } }
    .wrap_forbiz .section_contact .button-left {
      float: left !important;
    }
    .wrap_forbiz .section_contact .submit {
      margin-top: 2.77778vw;
      float: right;
      border: none;
      background: rgba(250, 165, 25, 0.25);
      padding-left: 2.63889vw;
      padding-top: 0.55556vw;
      padding-bottom: 0.55556vw;
      padding-right: 2.63889vw;
      color: white;
      text-decoration: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .submit {
          margin-top: 10.66667vw;
          padding-left: 10.13333vw;
          padding-top: 2.13333vw;
          padding-bottom: 2.13333vw;
          padding-right: 10.13333vw;
          width: 89.33333vw; } }
      .wrap_forbiz .section_contact .submit img {
        width: 1.52778vw;
        height: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_contact .submit img {
            width: 5.86667vw;
            height: 3.2vw; } }
      .wrap_forbiz .section_contact .submit span {
        font-size: 0.83333vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_contact .submit span {
            font-size: 3.2vw; } }
    .wrap_forbiz .section_contact h3 {
      font-size: 3.33333vw;
      letter-spacing: 0.05208vw;
      line-height: 4.16667vw;
      margin-bottom: 3.33333vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact h3 {
          font-size: 9.6vw;
          letter-spacing: 0.2vw;
          line-height: 12.8vw;
          margin-bottom: 12.8vw; } }
    .wrap_forbiz .section_contact .label {
      font-size: 1.11111vw;
      color: white; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .label {
          font-size: 4.26667vw; } }
    .wrap_forbiz .section_contact .form_block {
      margin: 0 auto;
      width: 59.44444vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .form_block {
          width: 89.33333vw; } }
    .wrap_forbiz .section_contact .lp_text_block {
      margin: 40px auto;
      width: 35vw;
      float:left; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .lp_text_block {
          width: 89.33333vw;} }
    .wrap_forbiz .section_contact .lp_text_block .section_portfolio {
      margin: 0 auto 5.55556vw;
      width: 35vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .lp_text_block .section_portfolio {
          width: 79.33333vw;} }
    .wrap_forbiz .section_contact .lp_text_block .section_portfolio img {
      display: block;
      width: 10vw;
      height: 10vw;
      margin-right: 1.38889vw;
      margin-top: 1.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .lp_text_block .section_portfolio img {
          width: 25vw;
          height: 25vw;} }
    .wrap_forbiz .section_contact .lp_form_block {
      margin: 40px auto;
      width: 41vw;
      float:right; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .lp_form_block {
          width: 89.33333vw;} }
    .wrap_forbiz .lp_form_block .form_group {
      margin-bottom: 4.47222vw; }
    .wrap_forbiz .lp_form_block .form_group input.text {
      width: 41vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .lp_form_block .form_group input.text {
          width: 89.33333vw; } }
    .wrap_forbiz .section_contact .form_row {
      display: flex;
      flex-direction: row; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .form_row {
          flex-direction: column; } }
    .wrap_forbiz .section_contact .whitepaper-download .whitepaper-img {
      border: 10px solid #f4f4f4;
      width: 500px;
    }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .whitepaper-download .whitepaper-img {
          width: 100%; } }
    .wrap_forbiz .section_contact .pt20 {
      padding-top: 1.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .pt20 {
          padding-top: 5.33333vw; } }
    .wrap_forbiz .section_contact .inverse {
      background: #000;
      padding: 20px 0;
      color: #fff; }
    .wrap_forbiz .section_contact .select-wrap {
      position: relative; }
    .wrap_forbiz .section_contact .select-wrap:before {
      z-index: 1;
      position: absolute;
      right: 2.43056vw;
      top: 0;
      font-family: "IonIcons";
      line-height: 2.08333vw;
      color: white;
      pointer-events: none; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact .select-wrap:before {
          right: 9.33333vw;
          line-height: 8vw; } }
    .wrap_forbiz .section_contact select {
      outline: none;
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: "";
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 2.77778vw;
      padding-left: 0.55556vw;
      border: none;
      background: rgba(255, 255, 255, 0.25);
      color: white;
      width: 29.02778vw;
      border-radius: 3px;
      margin-right: 1.38889vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_contact select {
          height: 10.66667vw;
          padding-left: 2.13333vw;
          width: 89.33333vw;
          border-radius: 3px;
          margin-right: 5.33333vw; } }
    .wrap_forbiz .section_contact select option {
      background-color: #fff;
      color: #333; }
    .wrap_forbiz .section_contact select::-ms-expand {
      display: none; }
    .wrap_forbiz .section_contact select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #828c9a; }
    .wrap_forbiz .section_contact .select-wrap.select-primary:before {
      color: #fff; }
    .wrap_forbiz .section_contact .select-wrap.select-primary > select {
      background: #0084b4;
      color: #fff;
      border-color: #0084b4; }
    .wrap_forbiz .section_contact .select-wrap.select-primary > select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #fff; }
    .wrap_forbiz .section_contact .select-wrap.select-inverse:before {
      color: #fff; }
    .wrap_forbiz .section_contact .select-wrap.select-inverse > select {
      color: #fff;
      border-color: #fff; }
    .wrap_forbiz .section_contact .select-wrap.select-inverse > select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #fff; }
  .wrap_forbiz .form_group {
    margin-bottom: 3.47222vw; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .form_group {
        margin-bottom: 8vw; } }
    .wrap_forbiz .form_group input {
      font-size: 1.11111vw;
      line-height: 1.66667vw;
      border: 1px solid #d7dae2;
      background: rgba(255, 255, 255, 0.25);
      border-radius: 0.27778vw;
      width: 23.19444vw;
      margin-right: 1.11111vw;
      padding-left: 1.11111vw;
      padding-right: 1.11111vw;
      height: 3.33333vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .form_group input {
          font-size: 4.26667vw;
          line-height: 6.4vw;
          border-radius: 1.06667vw;
          width: 89.33333vw;
          margin-right: 4.26667vw;
          padding-left: 4.26667vw;
          padding-right: 4.26667vw;
          height: 12.8vw; } }
    .wrap_forbiz .form_group textarea {
      width: 59.44444vw;
      height: 13.88889vw;
      border: 1px solid #d7dae2;
      background: rgba(255, 255, 255, 0.25);
      border-radius: 0;
      border: none;
      padding: 0.55556vw;
      color: white; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .form_group textarea {
          width: 89.33333vw;
          height: 53.33333vw;
          padding: 2.13333vw; } }
    .wrap_forbiz .form_group input.text {
      display: block;
      height: 2.77778vw;
      width: 29.02778vw;
      font-size: 1.11111vw;
      line-height: 2.08333vw;
      padding-left: 0.55556vw;
      padding-right: 0.55556vw;
      color: white;
      border: none;
      border-radius: 0; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .form_group input.text {
          height: 10.66667vw;
          width: 89.33333vw;
          font-size: 4.26667vw;
          line-height: 8vw;
          padding-left: 2.13333vw;
          padding-right: 2.13333vw; } }
    .wrap_forbiz .form_group .form_check {
      display: block;
      margin-top: 1.80556vw;
      /*Check box*/ }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .form_group .form_check {
          margin-top: 7.73333vw; } }
      .wrap_forbiz .form_group .form_check label {
        display: block;
        position: relative;
        cursor: pointer;
        color: white;
        font-size: 1.38889vw;
        margin-right: 3.47222vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .form_group .form_check label {
            font-size: 5.33333vw;
            width: 100%;
            margin-bottom: 5.33333vw; } }
        .wrap_forbiz .form_group .form_check label small {
          font-size: 1.11111vw;
          line-height: 1.38889vw;
          vertical-align: top;
          color: white;
          padding-left: 1.38889vw;
          vertical-align: text-top;
          padding-top: 2px;
          /* margin-top: 4px; */
          display: inline-block; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .form_group .form_check label small {
              font-size: 3.73333vw;
              line-height: 5.33333vw;
              width: 80vw;
              margin-left: 5.33333vw; } }
      .wrap_forbiz .form_group .form_check input[type="checkbox"] {
        position: absolute;
        right: 9000px;
        display: none; }
      .wrap_forbiz .form_group .form_check input[type="checkbox"] + .label-text:before {
        content: "\f096";
        font-family: "FontAwesome";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-right: 5px;
        color: #d7dae2;
        border-radius: 3px;
        height: 1.11111vw;
        width: 1.11111vw;
        background: rgba(255, 255, 255, 0.25); }
      .wrap_forbiz .form_group .form_check input[type="checkbox"]:checked + .label-text:before {
        content: "\f14a";
        color: white; }
      .wrap_forbiz .form_group .form_check input[type="checkbox"]:disabled + .label-text {
        color: #aaa; }
      .wrap_forbiz .form_group .form_check input[type="checkbox"]:disabled + .label-text:before {
        content: "\f0c8";
        color: #ccc; }
  .wrap_forbiz .section_detail_top {
    position: relative;
    background: url("/assets/images/forbiz/bg_wave.svg") no-repeat;
    background-size: 100% auto;
    background-position: top center;
    margin-top: 12.36111vw;
    height: 63.64097vw; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_detail_top {
        margin-top: 21.33333vw;
        height: 218.66667vw;
        background: url("/assets/images/forbiz/bg_wave_sp.svg") no-repeat; } }
    .wrap_forbiz .section_detail_top .wrap_contents {
      margin: 0 auto 5.55556vw;
      width: 79.72222vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_detail_top .wrap_contents {
          margin: 0 auto 21.33333vw;
          width: 89.33333vw; } }
      .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child {
        width: 39.16667vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child {
            width: 89.33333vw;
            margin-bottom: 10.66667vw; } }
        .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child h1 {
          margin-top: -4.16667vw;
          font-size: 4.16667vw;
          letter-spacing: 0.05208vw;
          line-height: 5vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child h1 {
              margin-top: 0;
              font-size: 12.8vw;
              letter-spacing: 0.2vw;
              line-height: 12.8vw; } }
        .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .subtitle {
          position: relative;
          color: white;
          font-size: 1.11111vw;
          line-height: 1.66667vw;
          letter-spacing: 0.06944vw;
          padding-left: 5.69444vw;
          margin-top: 3.05556vw;
          margin-bottom: 4.16667vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .subtitle {
              margin-bottom: 10.66667vw;
              margin-top: 10.66667vw;
              font-size: 4.26667vw;
              line-height: 5.86667vw;
              letter-spacing: 0.26667vw;
              padding-left: 16vw; } }
          .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .subtitle:before {
            left: 0;
            position: absolute;
            background: white;
            content: "";
            display: block;
            margin-top: 0.76389vw;
            width: 5.13889vw;
            height: 1px; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .subtitle:before {
                margin-top: 2.93333vw;
                width: 13.33333vw;
                height: 1px; } }
        .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .desc {
          font-size: 0.97222vw;
          line-height: 1.66667vw;
          letter-spacing: 0.06944vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_detail_top .wrap_contents .block_contents:first-child .desc {
              font-size: 3.73333vw;
              line-height: 6.4vw;
              letter-spacing: 0.06944vw; } }
      .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child {
        margin-top: 19.30556vw;
        width: 29.16667vw;
        margin-left: 11.38889vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child {
            margin-top: 4.26667vw;
            width: 89.33333vw;
            margin-left: 0; } }
        .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .title_exp {
          display: block;
          margin-bottom: 1.38889vw;
          font-size: 1.66667vw;
          line-height: 1.66667vw;
          letter-spacing: 0.05208vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .title_exp {
              margin-bottom: 5.33333vw;
              font-size: 6.4vw;
              line-height: 6.4vw;
              letter-spacing: 0.2vw; } }
        .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .block_exp {
          width: 32.5vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .block_exp {
              width: 89.33333vw; } }
          .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .block_exp img {
            width: 8.75vw;
            margin-right: 1.38889vw;
            margin-bottom: 1.38889vw; }
            @media screen and (max-width: 767px) {
              .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .block_exp img {
                width: 26.66667vw;
                margin-right: 4.53333vw;
                margin-bottom: 4.53333vw; }
                .wrap_forbiz .section_detail_top .wrap_contents .block_contents:last-child .block_exp img:nth-child(3n) {
                  margin-right: 0; } }
  .wrap_forbiz .wrap_stre {
    margin-top: -10.27778vw; }
  .wrap_forbiz .section_program_detail {
    width: 79.72222vw;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .wrap_forbiz .section_program_detail {
        width: 89.33333vw;
        margin: 0 auto; } }
    .wrap_forbiz .section_program_detail h1 {
      font-size: 3.33333vw;
      letter-spacing: 0.05208vw;
      line-height: 4.16667vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_program_detail h1 {
          font-size: 9.6vw;
          letter-spacing: 0.2vw;
          line-height: 12.8vw; } }
    .wrap_forbiz .section_program_detail .subtitle {
      position: relative;
      color: white;
      font-size: 1.11111vw;
      line-height: 2.22222vw;
      letter-spacing: 0.06944vw;
      padding-left: 5.69444vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_program_detail .subtitle {
          margin-bottom: 10.66667vw;
          margin-top: 10.66667vw;
          font-size: 4.26667vw;
          line-height: 5.86667vw;
          letter-spacing: 0.26667vw;
          padding-left: 16vw; } }
      .wrap_forbiz .section_program_detail .subtitle:before {
        left: 0;
        position: absolute;
        background: white;
        content: "";
        display: block;
        margin-top: 0.76389vw;
        width: 5.13889vw;
        height: 1px; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .subtitle:before {
            margin-top: 2.93333vw;
            width: 13.33333vw;
            height: 1px; } }
    .wrap_forbiz .section_program_detail .list_block {
      border-left: 1.38889vw solid #faa51a;
      padding-left: 4.44444vw;
      padding-right: 10.13889vw;
      margin-top: 5.27778vw;
      margin-bottom: 10.83333vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_program_detail .list_block {
          border-left: 5.33333vw solid #faa51a;
          padding-left: 5.33333vw;
          padding-right: 0;
          margin-top: 20.26667vw;
          margin-bottom: 41.6vw; } }
      .wrap_forbiz .section_program_detail .list_block h3 {
        font-size: 3.33333vw;
        letter-spacing: 0.05208vw;
        line-height: 4.16667vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block h3 {
            font-size: 12.8vw;
            letter-spacing: 0.2vw;
            line-height: 16vw; } }
      .wrap_forbiz .section_program_detail .list_block .desc {
        width: 43.54167vw;
        font-size: 1.11111vw;
        line-height: 2.22222vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .desc {
            width: 78.66667vw;
            font-size: 3.73333vw;
            line-height: 6.4vw; } }
      .wrap_forbiz .section_program_detail .list_block h4 {
        font-size: 1.66667vw;
        line-height: 2.77778vw; }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_01 {
          height: 2.77778vw;
          width: 2.77778vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_01 {
              height: 10.66667vw;
              width: 10.66667vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_02 {
          height: 2.5vw;
          width: 3.61111vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_02 {
              height: 9.6vw;
              width: 13.86667vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_03 {
          height: 2.91667vw;
          width: 2.91667vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_03 {
              height: 11.2vw;
              width: 11.2vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_04 {
          height: 2.84722vw;
          width: 2.84722vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_04 {
              height: 10.93333vw;
              width: 10.93333vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_05 {
          height: 3.125vw;
          width: 2.5vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_05 {
              height: 12vw;
              width: 9.6vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_06 {
          height: 2.98611vw;
          width: 2.98611vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_06 {
              height: 11.46667vw;
              width: 11.46667vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_07 {
          height: 2.5vw;
          width: 3.125vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_07 {
              height: 9.6vw;
              width: 12vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_08 {
          height: 2.5vw;
          width: 3.125vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_08 {
              height: 9.6vw;
              width: 12vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_09 {
          height: 2.98611vw;
          width: 2.98611vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_09 {
              height: 11.46667vw;
              width: 11.46667vw;
              margin-right: 6.93333vw; } }
        .wrap_forbiz .section_program_detail .list_block h4 img.icon_10 {
          height: 3.47222vw;
          width: 2.77778vw;
          margin-right: 1.80556vw;
          vertical-align: sub; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block h4 img.icon_10 {
              height: 13.33333vw;
              width: 10.66667vw;
              margin-right: 6.93333vw; } }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block h4 {
            font-size: 6.4vw;
            line-height: 10.66667vw; } }
      .wrap_forbiz .section_program_detail .list_block .content_block {
        margin-top: 2.77778vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .content_block {
            margin-top: 10.66667vw; } }
        .wrap_forbiz .section_program_detail .list_block .content_block:last-child .detail_block_type_2,
        .wrap_forbiz .section_program_detail .list_block .content_block:last-child .detail_block {
          border-left: none !important; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .content_block:last-child .detail_block_type_2 img {
            width: 78.66667vw;
            margin-left: -5.33333vw; } }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .content_block:last-child .detail_block_type_2 p {
            width: 68vw; } }
      .wrap_forbiz .section_program_detail .list_block .detail_block {
        margin-top: 2.22222vw;
        border-left: 0.13889vw solid white;
        margin-left: 1.38889vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .detail_block {
            margin-top: 8.53333vw;
            border-left: 0.53333vw solid white;
            margin-left: 2.66667vw;
            flex-wrap: wrap; } }
        .wrap_forbiz .section_program_detail .list_block .detail_block p {
          width: 30.13889vw;
          font-size: 0.97222vw;
          line-height: 1.73611vw;
          margin-left: 3.05556vw;
          margin-bottom: 2.77778vw;
          letter-spacing: 0; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block .detail_block p {
              width: 68vw;
              font-size: 3.2vw;
              line-height: 5.33333vw;
              margin-left: 6.66667vw;
              margin-bottom: 10.66667vw; } }
        .wrap_forbiz .section_program_detail .list_block .detail_block img {
          margin-top: -5vw;
          margin-left: 10.69444vw;
          width: 18.88889vw;
          height: auto; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block .detail_block img {
              width: 68vw;
              margin-top: 0;
              margin-left: 6.66667vw; } }
      .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 {
        margin-top: 2.22222vw;
        margin-left: 1.52778vw;
        border-left: 0.13889vw solid white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 {
            margin-top: 8.53333vw;
            margin-left: 2.66667vw;
            border-left: 0.13889vw solid white; } }
        .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 p {
          width: 39.16667vw;
          font-size: 0.97222vw;
          line-height: 1.73611vw;
          margin-left: 3.05556vw;
          margin-bottom: 2.77778vw;
          letter-spacing: 0; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 p {
              width: 68vw;
              font-size: 3.2vw;
              line-height: 5.33333vw;
              margin-left: 6.66667vw;
              margin-bottom: 0; } }
        .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 img {
          margin-top: 2.77778vw;
          margin-left: 23.05556vw;
          width: 39.16667vw;
          height: auto; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .list_block .detail_block_type_2 img {
              margin-top: 10.66667vw;
              margin-left: 6.66667vw;
              width: 68vw; } }
    .wrap_forbiz .section_program_detail .block_partner {
      margin-bottom: 11.11111vw; }
      @media screen and (max-width: 767px) {
        .wrap_forbiz .section_program_detail .block_partner {
          margin-bottom: 10.66667vw; } }
      .wrap_forbiz .section_program_detail .block_partner .title_block {
        position: relative; }
        .wrap_forbiz .section_program_detail .block_partner .title_block .tag {
          font-size: 0.83333vw;
          line-height: 1.38889vw;
          padding-top: 0.13889vw;
          padding-bottom: 0.13889vw;
          padding-left: 0.55556vw;
          padding-right: 0.55556vw;
          margin-right: 0.69444vw;
          margin-bottom: 0.69444vw;
          background: #faa51a; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .title_block .tag {
              font-size: 3.2vw;
              line-height: 5.33333vw;
              padding-top: 0.53333vw;
              padding-bottom: 0.53333vw;
              padding-left: 2.13333vw;
              padding-right: 2.13333vw;
              margin-right: 2.66667vw;
              margin-bottom: 2.66667vw; } }
        .wrap_forbiz .section_program_detail .block_partner .title_block h3 {
          font-size: 3.33333vw;
          letter-spacing: 0.05208vw;
          line-height: 4.16667vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .title_block h3 {
              font-size: 9.6vw;
              letter-spacing: 0.2vw;
              line-height: 9.6vw;
              margin-top: 3.2vw; } }
        .wrap_forbiz .section_program_detail .block_partner .title_block span {
          font-size: 1.66667vw;
          line-height: 2.5vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .title_block span {
              font-size: 3.2vw;
              line-height: 3.2vw; } }
        .wrap_forbiz .section_program_detail .block_partner .title_block .company_logo {
          position: absolute;
          top: 0;
          right: 0; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .title_block .company_logo {
              width: 26.66667vw;
              top: 7.46667vw; } }
      .wrap_forbiz .section_program_detail .block_partner .content_block {
        margin-top: 6.25vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .block_partner .content_block {
            margin-top: 5.33333vw;
            flex-wrap: wrap; } }
        .wrap_forbiz .section_program_detail .block_partner .content_block .content_title {
          margin-top: 0;
          font-size: 1.66667vw;
          line-height: 2.5vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .content_block .content_title {
              font-size: 4.8vw;
              line-height: 9.6vw;
              width: 89.33333vw; } }
        .wrap_forbiz .section_program_detail .block_partner .content_block p {
          margin-top: 1.94444vw;
          width: 39.16667vw;
          font-size: 0.97222vw;
          line-height: 1.73611vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .content_block p {
              margin-top: 7.46667vw;
              width: 89.33333vw;
              font-size: 3.73333vw;
              line-height: 6.66667vw; } }
        .wrap_forbiz .section_program_detail .block_partner .content_block .img_partner_detail {
          margin-top: 5.13889vw;
          width: 44.44444vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .content_block .img_partner_detail {
              margin-top: 7.46667vw;
              width: 89.33333vw; } }
        .wrap_forbiz .section_program_detail .block_partner .content_block .img_partner_thumb {
          margin-left: 6.25vw;
          width: 29.02778vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .block_partner .content_block .img_partner_thumb {
              margin-top: 12.8vw;
              width: 89.33333vw;
              margin-left: 0; } }
    .wrap_forbiz .section_program_detail .section_join {
      text-align: center; }
      .wrap_forbiz .section_program_detail .section_join h3 {
        font-size: 4.16667vw;
        margin-top: 5.20833vw;
        margin-bottom: 6.25vw;
        line-height: 4.16667vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .section_join h3 {
            font-size: 12.8vw;
            margin-top: 6.4vw;
            margin-bottom: 6.4vw;
            line-height: 12.8vw; } }
      .wrap_forbiz .section_program_detail .section_join p {
        display: block;
        width: 59.44444vw;
        margin: 0 auto;
        text-align: left;
        font-size: 1.11111vw;
        line-height: 2.5vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .section_join p {
            width: 89.33333vw;
            font-size: 3.73333vw;
            line-height: 6.4vw; } }
      .wrap_forbiz .section_program_detail .section_join .btn_ask {
        display: block;
        width: 29.02778vw;
        margin: 5.55556vw auto 7.63889vw; }
        @media screen and (max-width: 767px) {
          .wrap_forbiz .section_program_detail .section_join .btn_ask {
            width: 335px;
            margin: 6.4vw auto; } }
        .wrap_forbiz .section_program_detail .section_join .btn_ask img {
          width: 30.41667vw; }
          @media screen and (max-width: 767px) {
            .wrap_forbiz .section_program_detail .section_join .btn_ask img {
              width: 94.66667vw;
              margin-left: -10px; } }
